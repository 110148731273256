/* FONTS ------------------------------------------------------------ * /

/* Barlow-Black */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Black';
  src: url('../fonts/barlow/Barlow-Black.ttf'), url('../fonts/barlow/Barlow-BlackItalic.ttf');
}

/* Barlow-Bold */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Bold';
  src: url('../fonts/barlow/Barlow-Bold.ttf'), url('../fonts/barlow/Barlow-BoldItalic.ttf');
}

/* Barlow-ExtraBold */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow ExtraBold';
  src: url('../fonts/barlow/Barlow-ExtraBold.ttf'), url('../fonts/barlow/Barlow-ExtraBoldItalic.ttf');
}

/* Barlow-ExtraLight */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow ExtraLight';
  src: url('../fonts/barlow/Barlow-ExtraLight.ttf'), url('../fonts/barlow/Barlow-ExtraLightItalic.ttf');
}

/* Barlow-Italic */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Italic';
  src: url('../fonts/barlow/Barlow-Italic.ttf')
}

/* Barlow-Regular */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Regular';
  src: url('../fonts/barlow/Barlow-Regular.ttf')
}


/* Barlow-Light */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Light';
  src: url('../fonts/barlow/Barlow-Light.ttf'), url('../fonts/barlow/Barlow-LightItalic.ttf');
}

/* Barlow-Medium */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Medium';
  src: url('../fonts/barlow/Barlow-Medium.ttf'), url('../fonts/barlow/Barlow-MediumItalic.ttf');
}

/* Barlow-SemiBold */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow SemiBold';
  src: url('../fonts/barlow/Barlow-SemiBold.ttf'), url('../fonts/barlow/Barlow-SemiBoldItalic.ttf');
}

/* Barlow-Thin */
@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: 'Barlow Thin';
  src: url('../fonts/barlow/Barlow-Thin.ttf'), url('../fonts/barlow/Barlow-ThinItalic.ttf');
}

