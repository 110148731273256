@import 'src/assets/sass/fonts.scss';
@import 'src/assets/sass/variables.scss';

* {
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
  }

  &::-webkit-scrollbar-thumb {
    background: $green;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $green;
  }
}

body,
html {
  margin: 0;
  width: 100%;
  font-family: 'Barlow';
  background: #eaeaea;
}

.list-edit {
  svg {
    fill: $green;
  }
}

.list-delete {
  svg {
    fill: $secudary;
  }
}

.red {
  color: $primary;
}

.point {
  cursor: pointer;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.center-x {
  justify-content: center;
}

.center-y {
  align-items: center;
}

.mg-0 {
  margin: 0 !important;
}

.mg-auto {
  margin: auto !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.pd-0 {
  padding: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.card_footer {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.image-radios {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 100%;
}

.form {
  .MuiGrid-item {
    padding: 0px 12px !important;
  }
}

.hide {
  display: none !important;
}
