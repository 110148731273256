@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/fonts.scss';

.Sidebar {
  font-family: 'FiraCode Bold';

  .logo {
    text-align: center;
  }
  .background_siderbar {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-size: cover;
    background-blend-mode: multiply;
    background-position: center center;
    background-image: url(../../../assets/images/banner/banner.png);
    &::after {
      z-index: 3;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0.75;
      display: block;
      position: absolute;
      background: linear-gradient(280deg, #44475a -4.69%, #000 50.53%)
    }
  }
}
