$white: #fff;
$font-white: #f8f8f2;

$black: #000;
$dark: #2d2d2d;
$green:#98AF00;
$light-green:#98AF00;

$title: #192a3e;
$label: #90a0b7;
$subtitle: #6f6363;

$yellow: #ffc72c;

$error: #f44336;
$primary: #98AF00;
$secudary: #4D4D4D;
$primary_black: #009f28;

$ice: #F4F1F5;

$wine: #6B3D5C;
$orange: #FFA372;
$purple: #8300B9;
$purple-secondary: #A461D8;

$grey: #969da5;
$grey-white: #ebeff2;
$grey-dark: #DAD5DC;
$grey-secondary: #E8E2EA;

$dark3: #716974;
$dark4: #9A929D;

// Fonts
$font-bold: 'Barlow Bold';
$font-regular: 'Barlow Regular';
$font-semibold: 'Barlow SemiBold';
$font-extrabold: 'Barlow ExtraBold';
